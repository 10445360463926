<template>
  <fieldset>
    <DocFieldsZuEgrn
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields-bottom>
        <MultiSelectField
          :modelValue="modelValue.servitutes"
          @update:modelValue="update('servitutes', $event)"
          :options="vectors"
          @reload="fetchWithDelay"
          :itemValidationRule="itemValidationRule"
          data-qa="servitude"
          keyProp="externalId"
        >
          <label class="label"
            >Сервитут, публичный сервитут</label
          >
          <template #add>Добавить</template>
        </MultiSelectField>
      </template>
    </DocFieldsZuEgrn>
  </fieldset>
</template>

<script>
import DocWithServitute from "../../../models/documents/docWithServitute";
import DocFieldsZuEgrn from "./DocFieldsZuEgrn";
import MultiSelectField from "../../basic/MultiSelectField.vue";
import { useFetchEasement } from "../../../hooks/vector";
import { toRefs } from "vue";
import { helpers } from "@vuelidate/validators";
export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocWithServitute(),
    },
    documentTypeId:{
      type: Number,
      required: true
    },
    documentRegNum:{
      type: String,
      required: false
    }
  },
  components: { DocFieldsZuEgrn, MultiSelectField},
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);
    const { vectors, fetchWithDelay } = useFetchEasement();

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    let itemValidationRule = {
      externalId: helpers.withMessage("Заполните или удалите неиспользуемое поле", (value) => {
        return value !== null && value["externalId"] !== undefined
      })
    }

    return { data, update, vectors, fetchWithDelay, itemValidationRule };
  },
};
</script>